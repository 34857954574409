import { styled } from "styled-components";

const StyledLogo = styled.a`
    color: white;
    font-size: 30px;
    font-weight: 600;
`;

const Logo = () =>
    <StyledLogo href="/">UNITWATCH</StyledLogo>

export default Logo;
