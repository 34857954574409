import DataTable from "react-data-table-component";
import React from "react";
import {getAxios} from "../../utils/helper";
import {API_PATHS} from "../../utils";

const RepairLogsList = ({data, loading, fetchData, setLoading, setRepairLogId, setShow}) => {

    const deleteRepairLog = async (id) => {
        if (confirm("Are you sure you want to delete this repair log?")) {
            setLoading(true);
            const instance = getAxios();
            await instance.post(API_PATHS.DELETE_REPAIR_LOG_API, {id});
            fetchData();
        }
    }

    const editRepairLog = async (id) => {
        setRepairLogId(id);
        setShow(true);
    }

    const columns = [
        {
            name: "Property",
            selector: (row) => row.listing.title,
        },
        {
            name: "Asset",
            selector: (row) => row.asset? `${row.asset.name} - ${row.asset.type}` : "Not assigned",
        },
        {
            name: "Description",
            selector: (row) => row.description,
        },
        {
            name: "Cost",
            selector: (row) => row.cost,
        },
        {
            name: "Tax Rate",
            selector: (row) => row.taxRate,
        },
        {
            cell: (row) => (
                <>
                    <button
                        className="btn btn-sm btn-outline-primary"
                        onClick={() => editRepairLog(row.id)}
                    >
                        <i className="fa fa-pen"></i>
                    </button>
                    <button
                        className="btn btn-sm btn-outline-primary"
                        onClick={() => deleteRepairLog(row.id)}
                    >
                        <i className="fa fa-trash"></i>
                    </button>
                </>
            ),
            name: "Delete",
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];

    return (
        <DataTable
            title="Repair Logs"
            columns={columns}
            data={data}
            // selectableRows
            // contextActions={contextActions}
            // onSelectedRowsChange={handleRowSelected}
            // clearSelectedRows={toggleCleared}
            pagination
            dense
            progressPending={loading}
        />
    )
}

export default RepairLogsList;