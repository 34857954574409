/** @format */

import React, { useEffect, Suspense } from "react";

import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AOS, useJwt } from "./utils/Packages";
import { PATHS } from "./utils";
import { Error403, Error404 } from "./components/errors";


import { logoutAction } from "./redux/actions/APIs";
import { PrivateRoute, PublicRoute } from "./utils";
import Home from "./components/home/Home";
import ChangePassword from "./components/profile/ChangePassword";
import BasicDetails from "./components/profile/BasicDetails";
import {
  PropertyListing,
  AddProperty,
  PropertyOffer,
} from "./components/property";
import {RepairLogs} from "./components/repair-log";
import {Assets} from "./components/assets";
import VerifyUser from "./components/auth/VerifyUser";
import SignInPage from "./components/layout/PublicLayout/Content/SignInPage";
import SignUpPage from "./components/layout/PublicLayout/Content/SignUpPage";
import ForgotPasswordPage from "./components/layout/PublicLayout/Content/ForgotPasswordPage";
import ResetPasswordPage from "./components/layout/PublicLayout/Content/ResetPasswordPage";
const App = () => {
  const dispatch = useDispatch();

  const { isExpired } = useJwt(localStorage.getItem("access_token"));

  AOS.init();

  useEffect(() => {
    if (isExpired && localStorage.getItem("access_token")) {
      dispatch(logoutAction());
    }
  }, [isExpired]);

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <BrowserRouter>
        <Switch>
          <Route
            exact path={PATHS.LANDINGPAGE}
            component={() =>
              localStorage.getItem('access_token')
                ? <Redirect to={PATHS.HOME} />
                : <Redirect to={PATHS.SIGNIN} />
            }
          />
          <PublicRoute path={PATHS.VERIFYPASSWORD} component={VerifyUser} />
          <PublicRoute path={PATHS.RESETPASSWORD} component={ResetPasswordPage} />
          <PublicRoute path={PATHS.SIGNIN} component={SignInPage} />
          <PublicRoute path={PATHS.SIGNUP} component={SignUpPage} />
          <PublicRoute path={PATHS.FORGOT_PASSWORD} component={ForgotPasswordPage} />

          <PrivateRoute path={PATHS.PROPERTYLIST} component={PropertyListing} />
          <PrivateRoute path={PATHS.OFFERLIST} component={PropertyOffer} />
          <PrivateRoute path={PATHS.ADDPROPERTY} component={AddProperty} />
          <PrivateRoute path={PATHS.EDITPROPERTY} component={AddProperty} />

          <PrivateRoute path={PATHS.REPAIR_LOGS} component={RepairLogs} />
          <PrivateRoute path={PATHS.ASSETS} component={Assets} />

          <PrivateRoute exact path={PATHS.HOME} component={Home} />
          <PrivateRoute
            path={PATHS.CHANGEPASSWORD}
            component={ChangePassword}
          />
          <PrivateRoute
            exact
            path={PATHS.BASICDETAILS}
            component={BasicDetails}
          />
          <PrivateRoute path={PATHS.ACCESS_DENIED} component={Error403} />
          <PublicRoute path="*" component={Error404} />
        </Switch>
      </BrowserRouter>
    </Suspense>
  );
};

export default App;
