import { styled } from "styled-components";

const StyledContent = styled.section`
    grid-area: content;
    padding: 150px;

  @media (max-width: 1200px) {
    padding: 50px;
  }
  
    @media (max-width: 768px) {
        padding: 50px;
    }
`;

const Content = ({ children }) =>
    <StyledContent>
        {children}
    </StyledContent>;

export default Content;
