import { useForm, swal } from "../../../../../utils/Packages";
import { MSG } from "../../../../../utils/Messages";
import { EMAIL_REGEX } from "../../../../../utils/Regex";
import { isLoading, resetGuestResponse } from "../../../../../redux/actions";
import { forgotPassword } from "../../../../../redux/actions/APIs";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Page from './Page';
import { getUnauthedAxios } from "../../../../../utils/helper";
import Swal from "sweetalert2";
import { API_PATHS, PATHS } from "../../../../../utils";
import PublicLayout from "../..";

const ForgotPasswordPage = () => {

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        mode: "onTouched",
    });

    const [isLoading, setIsLoading] = useState(false);

    const _onSubmit = async (value) => {

        try {

            const axios = getUnauthedAxios();

            setIsLoading(true);

            const { data } = await axios.post(API_PATHS.FORGOT_API, value);

            if (data?.success) {

                await Swal.fire({
                    icon: "success",
                    title: "Success",
                    text: data?.message,
                });

                window.location.assign(PATHS.SIGNIN);

            } else {

                await Swal.fire({
                    icon: "error",
                    title: "Error",
                    timer: 5000,
                    text: data?.message,
                });

            }

        } catch (error) {

            await Swal.fire({
                icon: "error",
                title: "Error",
                timer: 5000,
                text: error.message,
            });

        } finally {

            setIsLoading(false);
        }
    };

    const props = {
        isDisabled: isLoading,
        email: {
            field: register("email", {
                required: {
                    value: true,
                    message: MSG.EMAILREQ,
                },
                pattern: {
                    value: EMAIL_REGEX,
                    message: MSG.INVEMAILREQ2,
                },
            }),
            error: errors.email?.message
        },
        onSubmit: handleSubmit(_onSubmit)
    };

    return (
        <PublicLayout>
            <Page {...props} />
        </PublicLayout>
    );
};

export default ForgotPasswordPage;
