import { styled } from "styled-components";
import Logo from "./Logo";
import { Link } from 'react-router-dom';
import Nav from "./Nav";

const StyledHeader = styled.header`
    grid-area: header;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 50px;
    background-color: #00863c;

    @media (max-width: 480px) {
        flex-direction: column;
        justify-content: center;
    }
`;

const Header = () =>
    <StyledHeader>
        <Logo />
        <Nav />
    </StyledHeader>;

export default Header;
