import { styled } from "styled-components";
import Header from "./Header";
import Banner from "./Banner";
import Content from "./Content";
import Footer from "./Footer";

const StyledPublicLayout = styled.div`
  display: grid;
  grid-template-areas:
    "header header"
    "banner content";
  grid-template-rows: 100px 1fr;
  grid-template-columns: 1fr 1fr;
  background-color: #222422;
  min-height: 100vh;
  height: 100%;

  @media (max-width: 768px) {
    grid-template-areas:
      "header"
      "content";

    grid-template-rows: 100px 1fr;
    grid-template-columns: 1fr;
  }

  @media (max-width: 480px) {
    grid-template-areas:
      "header"
      "content";

    grid-template-rows: 200px 1fr;
  }
`;

const PublicLayout = ({
  children
}) =>
  <StyledPublicLayout>
    <Header />
    <Banner />
    <Content>
      {children}
    </Content>
  </StyledPublicLayout>;

export default PublicLayout;
