import { styled } from "styled-components";

const StyledButton = styled.button`
    padding: 15px;
    border-radius: 10px;
    border: 4px solid #005326;
    background-color: #00863c;
    color: white;
    font-size: 1.2rem;

    &:disabled {
        background-color: gray;
        border-color: lightgray;
        cursor: default;
    }
`;

export default StyledButton;
