import { styled, css } from "styled-components";

const FormControl = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`;

const StyledInput = styled.input`
    padding: 15px;
    width: 100%;
    border-radius: 10px;
    border: 4px solid #ccc;
    font-size: 1.2rem;

    ${(props) =>
        props.invalid &&
        css`
            border-color: red;
        `}

    &:disabled {
        background: gray;
    }
`;

const ErrorMessage = styled.p`
  color: red;
`;

const Input = ({
    field,
    error,
    ...rest
}) => {

    return (
        <FormControl>
            <StyledInput invalid={error ? true : false} {...field} {...rest} />
            {error && <ErrorMessage>{error}</ErrorMessage>}
        </FormControl>
    );
};

export default Input;
