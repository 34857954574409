import {Offcanvas} from "react-bootstrap";
import AssetForm from "./AssetForm";

const AssetDrawer = ({handleClose, show, props, assetId, fetchData}) => {
    return (
        <>
         <Offcanvas show={show} onHide={handleClose} {...props}>
             <Offcanvas.Header closeButton>
                 <Offcanvas.Title>{assetId?'Update asset':'Create asset'}</Offcanvas.Title>
             </Offcanvas.Header>
             <Offcanvas.Body>
                 <AssetForm fetchData={fetchData} handleClose={handleClose} assetId={assetId}/>
             </Offcanvas.Body>
         </Offcanvas>
        </>
    );
};

export default AssetDrawer;