import { swal, useForm } from "../../../../../utils/Packages";
import { MSG } from "../../../../../utils/Messages";
import {
    EMAIL_REGEX,
    CHAR_REGEX,
} from "../../../../../utils/Regex";
import { userSignup } from "../../../../../redux/actions/APIs";
import { useDispatch } from "react-redux";
import { isLoading } from "../../../../../redux/actions";
import Page from "./Page";
import { getUnauthedAxios } from "../../../../../utils/helper";
import { API_PATHS, PATHS } from "../../../../../utils";
import { useState } from "react";
import Swal from "sweetalert2";
import PublicLayout from "../..";

const SignUpPage = () => {

    const [isLoading, setIsLoading] = useState(false);

    const {
        register,
        control,
        handleSubmit,
        watch,
        getValues,
        setValue,
        reset,
        setError,
        clearErrors,
        formState: { errors }
    } = useForm({ mode: "onTouched" });

    const _onSubmit = async (values) => {

        // if (passwordComplexity) {
        //     return false;
        // }

        // if (validateUser && validateUser?.message) {
        //     return false;
        // }

        let formData = {
            name: values.firstName,
            lastName: values.lastName,
            country: values.country,
            state: values.state,
            city: values.city,
            zip_code: values.zip_code,
            email: values.email,
            phone: values.phone,
            password: values.password,
            type: "property_owner",
            address: "values.address",
        };

        setIsLoading(true);

        try {
            const axios = getUnauthedAxios();

            const { data } = await axios.post(API_PATHS.FREE_SIGNUP_API, formData);

            if (data.success) {

                await Swal.fire({
                    icon: "success",
                    title: "Success",
                    html: `
                        <p>${data.message}</p>
                        <p>Please verify your email</p>
                    `,
                });

                window.location.assign(PATHS.SIGNIN);

            } else {

                await Swal.fire({
                    icon: "error",
                    title: "Error",
                    timer: 5000,
                    text: data.message,
                });
            }

        } catch (error) {

            await Swal.fire({
                icon: "error",
                title: "Error",
                timer: 5000,
                text: error.message,
            });

        } finally {
            setIsLoading(false);
        }
    };

    const props = {
        isDisabled: isLoading,
        firstName: {
            field: register("firstName", {
                required: {
                    value: true,
                    message: MSG.FNAMEREQ,
                },
                pattern: {
                    value: CHAR_REGEX,
                    message: MSG.ALPHAREQ2,
                },
                maxLength: {
                    value: 14,
                    message: MSG.MAX15CHREQ,
                },
            }),
            error: errors.firstName?.message
        },
        lastName: {
            field: register("lastName", {
                required: {
                    value: true,
                    message: MSG.LNAMEREQ,
                },
                pattern: {
                    value: CHAR_REGEX,
                    message: MSG.ALPHAREQ,
                },
                maxLength: {
                    value: 14,
                    message: MSG.MAX15CHREQ,
                },
            }),
            error: errors.lastName?.message
        },
        email: {
            field: register("email", {
                required: {
                    value: true,
                    message: MSG.EMAILREQ,
                },
                pattern: {
                    value: EMAIL_REGEX,
                    message: MSG.INVEMAILREQ2,
                },
            }),
            error: errors.email?.message
        },
        password: {
            field: register("password", {
                required: {
                    value: true,
                    message: MSG.PASSREQ,
                },
            }),
            error: errors.password?.message
        },
        passwordConfirm: {
            field: register("confirm_password", {
                onBlur: (e) => {
                    // checkPassword();
                },
                required: {
                    value: true,
                    message: MSG.CPASSREQ,
                },
                validate: (val) => {
                    if (watch("password") != val) {
                        return MSG.PWDNOTMTCH;
                    }
                },
            }),
            error: errors.confirm_password?.message
        },
        onSubmit: handleSubmit(_onSubmit)
    };

    return (
        <PublicLayout>
            <Page {...props} />
        </PublicLayout>
    ) ;
}

export default SignUpPage;
