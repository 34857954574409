import {getAxios} from "../../utils/helper";
import { API_PATHS, HOST_APP_BASE_PATH} from "../../utils";
import clsx from "clsx";
import {Formik} from "formik";
import * as Yup from "yup";
import {useEffect, useState} from "react";
import {CloseButton, Col, Form, Image, Row} from "react-bootstrap";

const repairLogSchema = Yup.object().shape({
    // listings_id: Yup.string(),
    name: Yup.string().nullable(),
    cost: Yup.number().nullable(),
    type: Yup.string().required(),
    brand: Yup.string().nullable(),
    model: Yup.string().nullable(),
    year: Yup.number().nullable(),
    serial: Yup.string().nullable(),
    description: Yup.string().nullable()
});

const defaultValues = {
    name: '',
    cost: '',
    listings_id: '',
    type: '',
    brand: '',
    model: '',
    year: '',
    serial: '',
    description: '',
    image: ''
};

const AssetForm = ({assetId, onChange, onSubmit, fetchData, handleClose}) => {
    const [propertyList, setPropertyList] = useState([]);
    const [initialValues, setInitialValues] = useState(defaultValues);
    useEffect(() => {
        const getPropertyList = async () => {
            const instance = getAxios();
            const {data} = await instance.post(API_PATHS.GET_PROPERTY_API);
            setPropertyList(data.data);
        }
        getPropertyList();
    }, []);

    useEffect(() => {

        if (assetId) {
            getAsset();
        } else {
            // setInitialValues(defaultValues);
        }
    }, [assetId]);

    const getAsset = async () => {
        const instance = getAxios();
        const {data} = await instance.get(API_PATHS.GET_ASSET_API, {params: {id: assetId}});

        setInitialValues(data.data);
    }
    const removeAssetImage = async (id)=>{
        if(confirm('Do you want to remove this image?')){
            const instance = getAxios();
            await instance.post(API_PATHS.REMOVE_ASSET_IMAGE_API, {id});
            if (assetId) {
                getAsset();
            }
        }
    }

    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={repairLogSchema}
            onSubmit={(values, {setSubmitting, resetForm}) => {
                setTimeout(async () => {
                    const instance = getAxios();

                    const formData = new FormData();
                    Object.keys(values).forEach(key => {
                        if(key === 'image'){
                            Array.from(values[key]).forEach((file)=>{
                                formData.append(`${key}[]`, file);
                            });
                        }else {
                            if(values[key] !== null) {
                                formData.append(key, values[key]);
                            }
                        }
                    });
                    if(assetId){
                        formData.append('id', assetId);
                    }

                    if (assetId) {
                        await instance.post(API_PATHS.UPDATE_ASSET_API, formData);
                    } else {
                        await instance.post(API_PATHS.CREATE_ASSET_API, formData);
                    }
                    setSubmitting(false);
                    resetForm();
                    await fetchData();
                    handleClose();
                }, 400);
            }}
        >
            {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  isValid,
                  getFieldProps,
                  setFieldValue
                  /* and other goodies */
              }) => (
                <Form onSubmit={handleSubmit} encType="multipart/form-data">
                    <Row className={'row'}>
                        <Col xs={12} className={'mb-4'}>
                            <Form.Group controlId="Name">
                                <Form.Label>Name</Form.Label>
                                <Form.Control
                                    type={'text'}
                                    name={'name'}
                                    className={
                                        clsx(
                                            'form-control',
                                            {"is-invalid": touched.name && errors.name},
                                            {"is-valid": touched.name && !errors.name}
                                        )
                                    }
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    {...getFieldProps('name')}
                                />
                                {touched.name && errors.name && (
                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">
                                            <span role="alert">{errors.name}</span>
                                        </div>
                                    </div>
                                )}
                            </Form.Group>
                        </Col>

                        <Col xs={12} className={'mb-4'}>
                            <Form.Group controlId="Type">
                                <Form.Label>Type</Form.Label>
                                <Form.Control
                                    type={'text'}
                                    name={'type'}
                                    className={
                                        clsx(
                                            'form-control',
                                            {"is-invalid": touched.type && errors.type},
                                            {"is-valid": touched.type && !errors.type}
                                        )
                                    }
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    {...getFieldProps('type')}
                                />
                                {touched.type && errors.type && (
                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">
                                            <span role="alert">{errors.type}</span>
                                        </div>
                                    </div>
                                )}
                            </Form.Group>
                        </Col>

                        <Col xs={12} className={'mb-4'}>
                            <Form.Group controlId="Cost">
                                <Form.Label>Cost</Form.Label>
                                <Form.Control
                                    type={'number'}
                                    name={'cost'}
                                    className={
                                        clsx(
                                            'form-control',
                                            {"is-invalid": touched.cost && errors.cost},
                                            {"is-valid": touched.cost && !errors.cost}
                                        )
                                    }
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    {...getFieldProps('cost')}
                                />
                                {touched.cost && errors.cost && (
                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">
                                            <span role="alert">{errors.cost}</span>
                                        </div>
                                    </div>
                                )}
                            </Form.Group>
                        </Col>

                        <Col xs={12} className={'mb-4'}>
                            <Form.Group controlId="Brand">
                                <Form.Label>Brand</Form.Label>
                                <Form.Control
                                    type={'text'}
                                    name={'brand'}
                                    className={
                                        clsx(
                                            'form-control',
                                            {"is-invalid": touched.brand && errors.brand},
                                            {"is-valid": touched.brand && !errors.brand}
                                        )
                                    }
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    {...getFieldProps('brand')}
                                />
                                {touched.brand && errors.brand && (
                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">
                                            <span role="alert">{errors.brand}</span>
                                        </div>
                                    </div>
                                )}
                            </Form.Group>
                        </Col>

                        <Col xs={12} className={'mb-4'}>
                            <Form.Group controlId="Model">
                                <Form.Label>Model</Form.Label>
                                <Form.Control
                                    type={'text'}
                                    name={'model'}
                                    className={
                                        clsx(
                                            'form-control',
                                            {"is-invalid": touched.model && errors.model},
                                            {"is-valid": touched.model && !errors.model}
                                        )
                                    }
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    {...getFieldProps('model')}
                                />
                                {touched.model && errors.model && (
                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">
                                            <span role="alert">{errors.model}</span>
                                        </div>
                                    </div>
                                )}
                            </Form.Group>
                        </Col>

                        <Col xs={12} className={'mb-4'}>
                            <Form.Group controlId="Year">
                                <Form.Label>Year</Form.Label>
                                <Form.Control
                                    type={'number'}
                                    name={'year'}
                                    className={
                                        clsx(
                                            'form-control',
                                            {"is-invalid": touched.year && errors.year},
                                            {"is-valid": touched.year && !errors.year}
                                        )
                                    }
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    {...getFieldProps('year')}
                                />
                                {touched.year && errors.year && (
                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">
                                            <span role="alert">{errors.year}</span>
                                        </div>
                                    </div>
                                )}
                            </Form.Group>
                        </Col>

                        <Col xs={12} className={'mb-4'}>
                            <Form.Group controlId="Serial">
                                <Form.Label>Serial</Form.Label>
                                <Form.Control
                                    type={'text'}
                                    name={'serial'}
                                    className={
                                        clsx(
                                            'form-control',
                                            {"is-invalid": touched.serial && errors.serial},
                                            {"is-valid": touched.serial && !errors.serial}
                                        )
                                    }
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    {...getFieldProps('serial')}
                                />
                                {touched.serial && errors.serial && (
                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">
                                            <span role="alert">{errors.serial}</span>
                                        </div>
                                    </div>
                                )}
                            </Form.Group>
                        </Col>

                        <Col xs={12} className={'mb-4'}>
                            <Form.Group controlId="Description">
                                <Form.Label>Description</Form.Label>
                                <Form.Control
                                    as={'textarea'}
                                    type={'text'}
                                    name={'description'}
                                    className={
                                        clsx(
                                            'form-control',
                                            {"is-invalid": touched.description && errors.description},
                                            {"is-valid": touched.description && !errors.description}
                                        )
                                    }
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    {...getFieldProps('description')}
                                />
                                {touched.description && errors.description && (
                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">
                                            <span role="alert">{errors.description}</span>
                                        </div>
                                    </div>
                                )}
                            </Form.Group>
                        </Col>


                        <Col xs={12} className={'form-col mb-4'}>
                            <Form.Group controlId="Listing">
                                <Form.Label>Listing</Form.Label>
                                <Form.Select
                                    name={'listings_id'}
                                    className={
                                        clsx(
                                            'form-control',
                                            {"is-invalid": touched.listings_id && errors.listings_id},
                                            {"is-valid": touched.listings_id && !errors.listings_id}
                                        )
                                    }
                                    placeholder={'Select Property'}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    {...getFieldProps('listings_id')}
                                >
                                    <option value={''}>Select Property</option>
                                    {propertyList.map((item, index) => (
                                        <option key={index} value={item.id}>{item.title}</option>
                                    ))}
                                </Form.Select>
                                {touched.listings_id && errors.listings_id && (
                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">
                                            <span role="alert">{errors.listings_id}</span>
                                        </div>
                                    </div>
                                )}
                            </Form.Group>
                        </Col>

                        <Col xs={12} className={'mb-4'}>
                            <Form.Group controlId="Images">
                                <Form.Label>Images</Form.Label>
                                <Form.Control
                                    type={'file'}
                                    name={'image'}
                                    multiple={true}
                                    accept='image/*'
                                    className={
                                        clsx(
                                            'form-control',
                                            {"is-invalid": touched.image && errors.image},
                                            {"is-valid": touched.image && !errors.image}
                                        )
                                    }
                                    onBlur={handleBlur}
                                    {...getFieldProps('image')}
                                    value={undefined}
                                    onChange={(e) => {
                                        setFieldValue('image', e.currentTarget.files);
                                    }}
                                />
                                {touched.image && errors.image && (
                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">
                                            <span role="alert">{errors.image}</span>
                                        </div>
                                    </div>
                                )}
                            </Form.Group>
                        </Col>

                        {values.images && values.images.length > 0 && (
                            <Col xs={12} className={'mb-4'}>
                                <div className={'row'}>
                                    {values.images.map((item, index) => (
                                        <div key={index} className={'col-4'}>
                                            <CloseButton onClick={()=>removeAssetImage(item.id)}/>
                                            <Image thumbnail src={`${HOST_APP_BASE_PATH}${item.path}`} alt={''} className={'img-fluid'}/>
                                        </div>
                                    ))}
                                </div>
                            </Col>
                        )}

                        <Col xs={12} className={'mb-4'}>
                            <button
                                type={'submit'}
                                className={'form-control btn btn-primary'}
                                disabled={isSubmitting || !isValid}
                            >{isSubmitting ? 'Saving...' : 'Save'}
                            </button>
                        </Col>
                    </Row>
                </Form>)}
        </Formik>
    )
}

export default AssetForm;