import React, {useEffect} from "react";
import Home from "../Home";
import RepairLogsList from "./RepairLogsList";
import {getAxios} from "../../utils/helper";
import {API_PATHS} from "../../utils";
import RepairLogDrawer from "./RepairLogDrawer";

const RepairLogs = () => {
    const [loading, setLoading] = React.useState(false);
    const [data, setData] = React.useState([]);
    const [show, setShow] = React.useState(false);
    const [repairLogId, setRepairLogId] = React.useState(null);

    const fetchData = async ()=>{
        setLoading(true);
        const instance = getAxios();
        const response = await instance.get(API_PATHS.GET_REPAIR_LOGS_API);
        setData(response.data.data);
        setLoading(false);
    }

    useEffect(()=>{
        fetchData()
    }, []);

    return (
        <Home>
            <section className="breadcrumbs section-bg-gray">
                <div className="container-fluid px-3 px-md-4">
                    <div className="d-flex justify-content-between align-items-center">
                        <h2>Repair Logs</h2>
                        <ol>
                            <li>
                                <a href="#">Home</a>
                            </li>
                            <li>Repair Logs</li>
                        </ol>
                    </div>
                </div>
            </section>
            <section className="inner-page-content">
                <div className="container">
                    <div>
                        <button
                            className={'btn btn-outline-primary float-right'}
                            type={'button'}
                            onClick={()=>{
                                setShow(!show);
                                setRepairLogId(null);
                            }}
                        >
                            Create Repair Log
                        </button>
                    </div>
                    <div>
                        <RepairLogsList data={data} loading={loading} fetchData={fetchData} setLoading={setLoading} setRepairLogId={setRepairLogId} setShow={setShow}/>
                    </div>
                </div>
            </section>
            <RepairLogDrawer
                show={show}
                handleClose={()=>setShow(!show)}
                props={{
                    placement: 'end',
                    backdrop: true
                }}
                repairLogId={repairLogId}
                fetchData={fetchData}
            />
        </Home>
    );
}

export default RepairLogs;