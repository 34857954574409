import {Formik} from "formik";
import {API_PATHS, HOST_APP_BASE_PATH} from "../../utils";
import {getAxios} from "../../utils/helper";
import {useEffect, useState} from "react";
import * as Yup from "yup";
import clsx from "clsx";
import {CloseButton, Col, Form, Image, Row} from "react-bootstrap";

const repairLogSchema = Yup.object().shape({
    listings_id: Yup.string().required(),
    cost: Yup.number().min(0, 'Min 0').required(),
    taxRate: Yup.number().min(0, 'Min 0').required(),
    description: Yup.string()
        .min(3, "Minimum 3 symbols")
        .required("Description is required"),
});

const defaultValues = {
    listings_id: '',
    asset_id: '',
    cost: '',
    taxRate: '',
    description: '',
};

const RepairLogForm = ({fetchData, repairLogId, handleClose}) => {
    const [propertyList, setPropertyList] = useState([]);
    const [assetList, setAssetList] = useState([]);
    const [initialValues, setInitialValues] = useState(defaultValues);
    const [listingId, setListingId] = useState(initialValues.listings_id);

    useEffect(() => {
        const getPropertyList = async () => {
            const instance = getAxios();
            const {data} = await instance.post(API_PATHS.GET_PROPERTY_API);
            setPropertyList(data.data);
        }
        getPropertyList();
    }, []);

    useEffect(()=>{
        const getAssetsList = async () => {
            const instance = getAxios();
            const {data} = await instance.get(API_PATHS.GET_ASSETS_API, {params:{listingId}});
            setAssetList(data.data);
        }

        getAssetsList();

    },[listingId]);

    useEffect(() => {

        if (repairLogId) {
            getRepairLog();
        } else {
            // setInitialValues(defaultValues);
        }
    }, [repairLogId]);

    useEffect(()=>{
        setListingId(initialValues.listings_id);
    },[initialValues]);


    const getRepairLog = async () => {
        const instance = getAxios();
        const {data} = await instance.get(API_PATHS.GET_REPAIR_LOG_API, {params: {id: repairLogId}});

        setInitialValues(data.data);
    }

    const removeRepairLogImage = async (id)=>{
        if(confirm('Do you want to remove this image?')){
            const instance = getAxios();
            await instance.post(API_PATHS.REMOVE_REPAIR_LOG_IMAGE_API, {id});
            if (repairLogId) {
                getRepairLog();
            }
        }
    }

    return (<>
        <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={repairLogSchema}
            onSubmit={(values, {setSubmitting, resetForm}) => {
                setTimeout(async () => {
                    const instance = getAxios();

                    const formData = new FormData();
                    Object.keys(values).forEach(key => {
                        if(key === 'image'){
                            Array.from(values[key]).forEach((file)=>{
                                formData.append(`${key}[]`, file);
                            });
                        }else {
                            if(values[key] !== null) {
                                formData.append(key, values[key]);
                            }
                        }
                    });
                    if(repairLogId){
                        formData.append('id', repairLogId);
                    }

                    if(repairLogId){
                        await instance.post(API_PATHS.UPDATE_REPAIR_LOG_API, formData);
                    }else {
                        await instance.post(API_PATHS.CREATE_REPAIR_LOG_API, formData);
                    }

                    setSubmitting(false);
                    resetForm();
                    await fetchData();
                    handleClose();
                }, 400);
            }}
        >
            {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  isValid,
                  getFieldProps,
                  setFieldValue
                  /* and other goodies */
              }) => (
                <Form onSubmit={handleSubmit}>
                    <Row className={'row'}>
                        <Col xs={12} className={'mb-4'}>
                            <Form.Group controlId="Listing">
                                <Form.Label>Property</Form.Label>
                                <Form.Select
                                    name={'listings_id'}
                                    className={
                                        clsx(
                                            'form-control',
                                            {"is-invalid": touched.listings_id && errors.listings_id},
                                            {"is-valid": touched.listings_id && !errors.listings_id}
                                        )
                                    }
                                    placeholder={'Select Property'}

                                    onBlur={handleBlur}
                                    value={values.listing_id}
                                    {...getFieldProps('listings_id')}
                                    onChange={(e)=>{
                                        setListingId(e.target.value);
                                        setFieldValue('listings_id', e.target.value);
                                    }}
                                >
                                    <option value={''}>Select Property</option>
                                    {propertyList.map((item, index) => (
                                        <option key={index} value={item.id}>{item.title}</option>
                                    ))}
                                </Form.Select>
                                {touched.listings_id && errors.listings_id && (
                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">
                                            <span role="alert">{errors.listings_id}</span>
                                        </div>
                                    </div>
                                )}
                            </Form.Group>
                        </Col>

                        <Col xs={12} className={'mb-4'}>
                            <Form.Group controlId="Asset">
                                <Form.Label>Asset</Form.Label>
                                <Form.Select
                                    disabled={!values.listings_id}
                                    name={'asset_id'}
                                    className={
                                        clsx(
                                            'form-control',
                                            {"is-invalid": touched.asset_id && errors.asset_id},
                                            {"is-valid": touched.asset_id && !errors.asset_id}
                                        )
                                    }
                                    placeholder={'Select Asset'}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.listing_id}
                                    {...getFieldProps('asset_id')}
                                >
                                    <option value={''}>Select Asset</option>
                                    {assetList.map((item, index) => (
                                        <option key={index} value={item.id}>{item.name} - {item.type}</option>
                                    ))}
                                </Form.Select>
                                {touched.asset_id && errors.asset_id && (
                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">
                                            <span role="alert">{errors.asset_id}</span>
                                        </div>
                                    </div>
                                )}
                            </Form.Group>
                        </Col>

                        <Col xs={12} className={'mb-4'}>
                            <Form.Group controlId="Cost">
                                <Form.Label>Cost</Form.Label>
                                <Form.Control
                                    type={'number'}
                                    name={'cost'}
                                    className={
                                        clsx(
                                            'form-control',
                                            {"is-invalid": touched.cost && errors.cost},
                                            {"is-valid": touched.cost && !errors.cost}
                                        )
                                    }
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    {...getFieldProps('cost')}
                                />
                                {touched.cost && errors.cost && (
                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">
                                            <span role="alert">{errors.cost}</span>
                                        </div>
                                    </div>
                                )}
                            </Form.Group>
                        </Col>
                        <Col xs={12} className={'mb-4'}>
                            <Form.Group controlId="TaxRate">
                                <Form.Label>Tax Rate</Form.Label>
                                <Form.Control
                                    type={'number'}
                                    name={'taxRate'}
                                    className={
                                        clsx(
                                            'form-control',
                                            {"is-invalid": touched.taxRate && errors.taxRate},
                                            {"is-valid": touched.taxRate && !errors.taxRate}
                                        )
                                    }
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    {...getFieldProps('taxRate')}
                                />
                                {touched.taxRate && errors.taxRate && (
                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">
                                            <span role="alert">{errors.taxRate}</span>
                                        </div>
                                    </div>
                                )}
                            </Form.Group>
                        </Col>
                        <Col xs={12} className={'mb-4'}>
                            <Form.Group controlId="Description">
                                <Form.Label>Description</Form.Label>
                                <Form.Control
                                    as={'textarea'}
                                    type={'text'}
                                    name={'description'}
                                    className={
                                        clsx(
                                            'form-control',
                                            {"is-invalid": touched.description && errors.description},
                                            {"is-valid": touched.description && !errors.description}
                                        )
                                    }
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    {...getFieldProps('description')}
                                />
                                {touched.description && errors.description && (
                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">
                                            <span role="alert">{errors.description}</span>
                                        </div>
                                    </div>
                                )}
                            </Form.Group>
                        </Col>

                        <Col xs={12} className={'mb-4'}>
                            <Form.Group controlId="Images">
                                <Form.Label>Images</Form.Label>
                                <Form.Control
                                    type={'file'}
                                    name={'image'}
                                    multiple={true}
                                    accept='image/*'
                                    className={
                                        clsx(
                                            'form-control',
                                            {"is-invalid": touched.image && errors.image},
                                            {"is-valid": touched.image && !errors.image}
                                        )
                                    }
                                    onBlur={handleBlur}
                                    {...getFieldProps('image')}
                                    value={undefined}
                                    onChange={(e) => {
                                        setFieldValue('image', e.currentTarget.files);
                                    }}
                                />
                                {touched.image && errors.image && (
                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">
                                            <span role="alert">{errors.image}</span>
                                        </div>
                                    </div>
                                )}
                            </Form.Group>
                        </Col>

                        {values.images && values.images.length > 0 && (
                            <Col xs={12} className={'mb-4'}>
                                <div className={'row'}>
                                    {values.images.map((item, index) => (
                                        <div key={index} className={'col-4'}>
                                            <CloseButton onClick={() => removeRepairLogImage(item.id)}/>
                                            <Image thumbnail src={`${HOST_APP_BASE_PATH}${item.path}`} alt={''}
                                                   className={'img-fluid'}/>
                                        </div>
                                    ))}
                                </div>
                            </Col>
                        )}

                        <Col xs={12} className={'mb-4'}>
                            <button
                                type={'submit'}
                                className={'form-control btn btn-primary'}
                                disabled={isSubmitting || !isValid}
                            >{isSubmitting ? 'Saving...' : 'Save'}
                            </button>
                        </Col>
                    </Row>
                </Form>)}
        </Formik>
    </>);
}

export default RepairLogForm;