/** @format */

import React, {useState, useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";
import {useParams} from "react-router-dom";

import {addProperty, getPropertyDetail, updateProperty} from "../../redux/actions/APIs";

import {MSG} from "../../utils/Messages";

import Home from "../Home";

import {
    useForm,
    useHistory,
} from "../../utils/Packages";
import {API_PATHS, PATHS} from "../../utils";
import {Col, Row} from "react-bootstrap";
import {GoogleMap, LoadScript, Autocomplete, Marker} from '@react-google-maps/api';
import {DETAILPROPERTY} from "../../redux/actions/Types";
import {getAxios} from "../../utils/helper";
import {Spinner} from "../base";
import DataTable from "react-data-table-component";


const AddProperty = () => {

    const {
        register,
        control,
        handleSubmit,
        watch,
        getValues,
        setValue,
        reset,
        setError,
        clearErrors,
        trigger,
        formState: {errors},
    } = useForm({mode: "onTouched"});

    const dispatch = useDispatch();
    const history = useHistory();
    const [autocomplete, setAutocomplete] = useState(null);

    const [lat, setLat] = useState(40.756795);
    const [long, setLong] = useState(-73.954298);
    const [zoom, setZoom] = useState(2.5);
    const [center, setCenter] = useState({lat, lng: long});

    const [repairLogsLoading, setRepairLogsLoading] = useState(false);
    const [repairLogsData, setRepairLogsData] = useState([]);

    const [assetsLoading, setAssetsLoading] = useState(false);
    const [assetsData, setAssetsData] = useState([]);

    const param = useParams();

    useEffect(() => {
        if (param?.slug) {
            dispatch(getPropertyDetail(param?.slug));
        }
    }, [param]);
    const {propertyData} = useSelector((state) => state.collections);

    console.log({'propertyData: ': propertyData?.data});

    useEffect(() => {
        if (propertyData?.data && param?.slug) {
            const fields = [
                "title",
                "description",
                "zip_code",
                "longtitue",
                "lat",
                "country",
                "state",
                "city",
                "address"
            ];

            fields.forEach((field) => {
                if (propertyData?.data[field] && propertyData?.data[field] != "null") {
                    setValue(field, propertyData?.data[field]);
                } else {
                    setValue(field, "");
                }
            });

            if (propertyData?.data?.lat && propertyData?.data?.longtitue) {
                console.log({
                    lat: propertyData?.data?.lat,
                    long: propertyData?.data?.longtitue,
                })
                setLat(parseFloat(propertyData?.data?.lat));
                setLong(parseFloat(propertyData?.data?.longtitue));
                setZoom(18);
            }

            console.log(propertyData?.data);
        }
    }, [propertyData]);

    const {response_ok, cmsData} = useSelector((state) => state.collections);
    const {loading, validateUser} = useSelector((state) => state.collections);

    const _onSubmit = (data) => {
        const formData = new FormData();

        formData.append("address", data.address);
        formData.append("city", data.city);
        formData.append("country", data.country);
        formData.append("description", data.description);
        formData.append("lat", data.lat);
        formData.append("longtitue", data.longtitue);
        formData.append("state", data.state);
        formData.append("title", data.title);
        formData.append("zip_code", data.zip_code);

        if (param?.slug) {
            formData.append("id", propertyData?.data?.id);
            dispatch(updateProperty(formData));
        } else {
            dispatch(addProperty(formData));
        }
    };
    useEffect(() => {
        if (response_ok) {
            dispatch(addProperty());
            history.push(PATHS.PROPERTYLIST);
        }
    }, [response_ok]);

    useEffect(() => {
        setCenter({lat, lng: long});
    }, [lat, long]);


    const containerStyle = {
        width: '100%',
        height: '400px'
    };

    const handlePlaceChanged = () => {
        if (autocomplete !== null) {
            const placeData = autocomplete.getPlace();

            setLat(placeData.geometry.location.lat());
            setLong(placeData.geometry.location.lng());
            setZoom(18);

            console.log({
                lat: placeData.geometry.location.lat(),
                lon: placeData.geometry.location.lng()
            });

            const countryCode = placeData.address_components.find((data) => data.types.includes('country')).short_name;
            const stateCode = placeData.address_components.find((data) => data.types.includes('administrative_area_level_1')).short_name;
            const city = placeData.address_components.find((data) => data.types.includes('locality')).long_name;
            const zip_code = placeData.address_components.find((data) => data.types.includes('postal_code'))?.long_name;

            setValue('country', countryCode);
            setValue('state', stateCode);
            setValue('city', city);
            setValue('zip_code', zip_code);
            setValue('lat', placeData.geometry.location.lat());
            setValue('longtitue', placeData.geometry.location.lng());
            setValue('address', placeData.formatted_address);

            trigger(['country', 'state', 'city', 'zip_code', 'lat', 'longtitue']);

            console.log('place changed data: ', placeData);
        }
    }

    // Repair Logs
    const fetchRepairLogData = async (id)=>{
        setRepairLogsLoading(true);
        const instance = getAxios();
        const response = await instance.get(API_PATHS.GET_REPAIR_LOGS_API, {params:{listingId: id}});
        setRepairLogsData(response.data.data);
        setRepairLogsLoading(false);
    }

    useEffect(()=> {
        if(propertyData?.data?.id){
            fetchRepairLogData(propertyData?.data?.id);
            fetchAssetsData(propertyData?.data?.id);
        }
    }, [propertyData?.data?.id])

    const repairLogsColumn = [
        {
            name: "Asset",
            selector: (row) => row.asset? `${row.asset.name} - ${row.asset.type}` : "Not assigned",
        },
        {
            name: "Description",
            selector: (row) => row.description,
        },
        {
            name: "Cost",
            selector: (row) => row.cost,
        },
        {
            name: "Tax Rate",
            selector: (row) => row.taxRate,
        },
    ];

    // Assets
    const fetchAssetsData = async (id)=>{
        setAssetsLoading(true);
        const instance = getAxios();
        const response = await instance.get(API_PATHS.GET_ASSETS_API, {params:{listingId: id}});
        setAssetsData(response.data.data);
        setAssetsLoading(false);
    }

    const assetsColumn = [
        {
            name: "Name",
            selector: (row) => row.name,
        },
        {
            name: "Type",
            selector: (row) => row.type,
        },
        {
            name: "Cost",
            selector: (row) => row.cost,
        },
        {
            name: "Brand",
            selector: (row) => row.brand,
        },
        {
            name: "Model",
            selector: (row) => row.model,
        },
        {
            name: "Year",
            selector: (row) => row.year,
        },
        {
            name: "Serial",
            selector: (row) => row.serial,
        }
    ];

    return (
        <Home>
            <section className="breadcrumbs section-bg-gray">
                <div className="container-fluid px-3 px-md-4">
                    <div className="d-flex justify-content-between align-items-center">
                        <h2>Property Listing</h2>
                        <ol>
                            <li>
                                <a href="#">Home</a>
                            </li>
                            <li>Property Listing</li>
                        </ol>
                    </div>
                </div>
            </section>

            <section className="inner-page-content">
                <div className="container">
                    <div className="row justify-content-center">
                        <form
                            name="addproperty"
                            className="content"
                            onSubmit={handleSubmit(_onSubmit)}
                        >
                            <div className="row">
                                <div className="col-md-6 pos-rel mb-3">
                                    <label className="mb-2">Title</label>
                                    <input
                                        type="text"
                                        className={`form-control ${
                                            errors.title ? "is-invalid" : ""
                                        }`}
                                        {...register("title", {
                                            required: {
                                                value: true,
                                                message: MSG.FNAMEREQ,
                                            },
                                        })}
                                    />

                                    <div className="invalid-feedback">
                                        {errors?.title?.message}
                                    </div>
                                </div>
                                <div className="col-md-6 pos-rel mb-3">
                                    <label className="mb-2">Description</label>
                                    <input
                                        type="text"
                                        // placeholder="Last Name"
                                        className={`form-control ${
                                            errors.description ? "is-invalid" : ""
                                        }`}
                                        {...register("description", {
                                            required: {
                                                value: true,
                                                message: MSG.LNAMEREQ,
                                            },
                                        })}
                                    />
                                    <div className="invalid-feedback">
                                        {errors?.description?.message}
                                    </div>
                                </div>
                            </div>
                            <Row>
                                <Col md={12} mb={3}>
                                    <LoadScript
                                        googleMapsApiKey="AIzaSyDpVIAIg5wuYvjg9kd7KMW6quVrfU2zlyg"
                                        libraries={["places"]}
                                    >
                                        <GoogleMap
                                            id="searchbox-example"
                                            mapContainerStyle={containerStyle}
                                            zoom={zoom}
                                            center={center}
                                        >
                                            <Autocomplete
                                                onLoad={(e) => {
                                                    setAutocomplete(e);
                                                }}
                                                onPlaceChanged={(e) => {
                                                    console.log('place changed: ');
                                                    handlePlaceChanged();
                                                }}


                                            >
                                                <input
                                                    type="text"
                                                    placeholder={getValues('address') ?? "Enter address"}
                                                    style={{
                                                        boxSizing: `border-box`,
                                                        border: `1px solid transparent`,
                                                        width: `340px`,
                                                        height: `32px`,
                                                        padding: `0 12px`,
                                                        borderRadius: `3px`,
                                                        boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                                                        fontSize: `14px`,
                                                        outline: `none`,
                                                        textOverflow: `ellipses`,
                                                        position: "absolute",
                                                        left: "45%",
                                                        marginLeft: "-120px"
                                                    }}

                                                />
                                            </Autocomplete>
                                            <Marker position={{lat: parseFloat(lat), lng: parseFloat(long)}}/>
                                        </GoogleMap>
                                    </LoadScript>
                                </Col>
                            </Row>

                            <Row>
                                <Col md={6} mb={6}>
                                    <label className={'mb-2'}>Country</label>
                                    <input type={'text'} disabled={true} className={'form-control'}
                                           value={getValues('country')}/>
                                </Col>
                                <Col md={6} mb={6}>
                                    <label className={'mb-2'}>State</label>
                                    <input type={'text'} disabled={true} className={'form-control'}
                                           value={getValues('state')}/>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6} mb={6}>
                                    <label className={'mb-2'}>City</label>
                                    <input type={'text'} disabled={true} className={'form-control'}
                                           value={getValues('city')}/>
                                </Col>
                                <Col md={6} mb={6}>
                                    <label className={'mb-2'}>ZIP</label>
                                    <input type={'text'} disabled={true} className={'form-control'}
                                           value={getValues('zip_code')}/>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6} mb={6}>
                                    <label className={'mb-2'}>Latitude</label>
                                    <input type={'text'} disabled={true} className={'form-control'}
                                           value={getValues('lat')}/>
                                </Col>
                                <Col md={6} mb={6}>
                                    <label className={'mb-2'}>Longitude</label>
                                    <input type={'text'} disabled={true} className={'form-control'}
                                           value={getValues('longtitue')}/>
                                </Col>
                            </Row>

                            <div className="row">
                                <div className="col mt-2">
                                    {loading ? (
                                        <button className="black-btn btn-small" key={Math.random()}>
                                            <span className="RounAnimation"></span> Please Wait...
                                        </button>
                                    ) : (
                                        <button
                                            key={Math.random()}
                                            type="submit"
                                            className="black-btn btn-small"
                                        >
                                            <i className="fa-solid fa-paper-plane"></i> Save
                                        </button>
                                    )}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="container">
                    <div className="row justify-content-center">
                        <Row>
                            <Col md={6} mb={12}>
                                {repairLogsLoading && (<Spinner animation="border" role="status">Loading...</Spinner>)}
                                {repairLogsData && repairLogsData.length > 0 && (
                                    <DataTable
                                        title="Repair Logs"
                                        columns={repairLogsColumn}
                                        data={repairLogsData}
                                        pagination
                                        dense
                                        progressPending={repairLogsLoading}
                                    />
                                )}
                            </Col>
                            <Col md={6} mb={12}>
                                {assetsData && assetsData.length > 0 && (
                                    <DataTable
                                        title="Assets"
                                        columns={assetsColumn}
                                        data={assetsData}
                                        pagination
                                        dense
                                        progressPending={assetsLoading}
                                    />
                                )}
                            </Col>
                        </Row>
                    </div>
                </div>
            </section>
        </Home>
    );
};

export default AddProperty;
