import { styled } from "styled-components";
import Input from "../../components/Input";
import Button from '../../components/Button';
import { Link } from "react-router-dom";
import { PATHS } from "../../../../../utils";

const Fieldset = styled.fieldset`
  display: flex;
  flex-direction: column;
  gap: 50px;
`;

const SignUpButton = styled(Button)`
  width: 100%;
`;

const SignInLink = styled(Link)`
  align-self: center;
  padding: 15px;
  border-radius: 10px;
  background-color: #bbc900;
  color: white;
  text-align: center;
  font-size: 1.2rem;
`;

const Page = ({
  firstName,
  lastName,
  email,
  password,
  passwordConfirm,
  isDisabled,
  onSubmit
}) =>
  <form onSubmit={onSubmit}>
    <Fieldset disabled={isDisabled}>
      <Input type="text" placeholder="First name" {...firstName} />
      <Input type="text" placeholder="Last name" {...lastName} />
      <Input type="email" placeholder="Email" {...email} />
      <Input type="password" placeholder="Password" {...password} />
      <Input type="password" placeholder="Confirm password" {...passwordConfirm} />
      <SignUpButton type="submit">Sign Up</SignUpButton>
      <hr />
      <SignInLink to={PATHS.SIGNIN}>Log In</SignInLink>
    </Fieldset>
  </form>;

export default Page;
