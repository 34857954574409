import React, {useEffect} from "react";
import Home from "../Home";
// import RepairLogForm from "./RepairLogForm";
import {getAxios} from "../../utils/helper";
import {API_PATHS} from "../../utils";
import {AssetsList} from "./index";
import AssetDrawer from './AssetDrawer'
import {Offcanvas} from "react-bootstrap";

const Assets = () => {
    const [loading, setLoading] = React.useState(false);
    const [data, setData] = React.useState([]);
    const [show, setShow] = React.useState(false);
    const [assetId, setAssetId] = React.useState(null);

    const fetchData = async ()=>{
        setLoading(true);
        const instance = getAxios();
        const response = await instance.get(API_PATHS.GET_ASSETS_API);
        setData(response.data.data);
        setLoading(false);
    }

    useEffect(()=>{
        fetchData()
    }, []);

    return (
        <Home>
            <section className="breadcrumbs section-bg-gray">
                <div className="container-fluid px-3 px-md-4">
                    <div className="d-flex justify-content-between align-items-center">
                        <h2>Assets</h2>
                        <ol>
                            <li>
                                <a href="/">Home</a>
                            </li>
                            <li>Assets</li>
                        </ol>
                    </div>
                </div>
            </section>
            <section className="inner-page-content">
                <div className="container">
                    <div>
                        <button
                            className={'btn btn-outline-primary float-right'}
                            type={'button'}
                            onClick={()=>{
                                setShow(!show);
                                setAssetId(null);
                            }}
                        >
                            Create Asset
                        </button>
                    </div>
                    <div>
                        <AssetsList data={data} loading={loading} fetchData={fetchData} setLoading={setLoading} setAssetId={setAssetId} setShow={setShow}/>
                    </div>
                </div>
            </section>
            <AssetDrawer
                show={show}
                handleClose={()=>setShow(!show)}
                props={{
                    placement: 'end',
                    backdrop: true
                }}
                assetId={assetId}
                fetchData={fetchData}
            />
        </Home>
    );
}

export default Assets;