import { styled } from "styled-components";
import Input from "../../components/Input";
import Button from '../../components/Button';
import { Link } from "react-router-dom";
import { MSG } from "../../../../../utils/Messages";
import { EMAIL_REGEX } from "../../../../../utils/Regex";
import { useForm } from "react-hook-form";
import { userSignin } from "../../../../../redux/actions/APIs";
import { useDispatch, useSelector } from "react-redux";
import { API_PATHS, PATHS } from "../../../../../utils";
import { useEffect, useState } from "react";
import Page from "./Page";
import Swal from "sweetalert2";
import { getAxios } from "../../../../../utils/helper";
import PublicLayout from "../..";

const SignInPage = () => {

  const [isLoading, setIsLoading] = useState(false);

  const { register, handleSubmit, setValue, formState } = useForm({
    mode: "onTouched",
  });

  const { errors } = formState;

  const _onSubmit = async (values) => {

    try {

      const axios = getAxios();

      setIsLoading(true);

      const { data } = await axios.post(API_PATHS.FREE_SIGNIN_API, values);

      if (data?.success) {

        localStorage.setItem("access_token", data?.data?.token);

        window.location.assign(PATHS.HOME);
        
      } else {
        
        await Swal.fire({
            icon: "error",
            title: "Error",
            text: data.message,
        });
      }

    } catch (error) {

      await Swal.fire({
        icon: "error",
        title: "Error",
        text: error.message,
      });

    } finally {

      setIsLoading(false);
    }
  };

  const props = {
    isDisabled: isLoading,
    email: {
      field: register("email", {
        required: {
          value: true,
          message: MSG.EMAILREQ,
        },
        pattern: {
          value: EMAIL_REGEX,
          message: MSG.INVEMAILREQ2,
        },
      }),
      error: errors.email?.message,
    },
    password: {
      field: register("password", {
        required: {
          value: true,
          message: MSG.PASSREQ,
        },
      }),
      error: errors.password?.message,
    },
    onSubmit: handleSubmit(_onSubmit),
  };

  return (
    <PublicLayout>
      <Page {...props} />
    </PublicLayout>
  );
};

export default SignInPage;
