import { styled } from "styled-components";
import Input from "../../components/Input";
import Button from '../../components/Button';
import { Link } from "react-router-dom";
import { PATHS } from "../../../../../utils";

const Fieldset = styled.fieldset`
  display: flex;
  flex-direction: column;
  gap: 50px;
`;

const SignInLink = styled(Link)`
  align-self: center;
  padding: 15px;
  border-radius: 10px;
  background-color: #bbc900;
  color: white;
  text-align: center;
  font-size: 1.2rem;
`;

const Page = ({
  isDisabled,
  email,
  onSubmit,
}) =>
  <form onSubmit={onSubmit}>
    <Fieldset disabled={isDisabled}>
      <Input type="text" placeholder="Email" {...email} />
      <Button type="submit">Reset password</Button>

      <hr />

      <SignInLink to={PATHS.SIGNIN}>Log In</SignInLink>
    </Fieldset>
  </form>;

export default Page;
