import DataTable from "react-data-table-component";
import React from "react";
import {getAxios} from "../../utils/helper";
import {API_PATHS} from "../../utils";

const AssetsList = ({data, loading, fetchData, setLoading, setAssetId, setShow}) => {

    const deleteAsset = async (id) => {
        if (confirm("Are you sure you want to delete this asset?")) {
            setLoading(true);
            const instance = getAxios();
            await instance.post(API_PATHS.DELETE_ASSET_API, {id});
            fetchData();
        }
    }

    const editAsset = async (id) => {
        setAssetId(id);
        setShow(true);
    }

    const columns = [
        {
            name: "Name",
            selector: (row) => row.name,
        },
        {
            name: "Type",
            selector: (row) => row.type,
        },
        {
            name: "Cost",
            selector: (row) => row.cost,
        },
        {
            name: "Brand",
            selector: (row) => row.brand,
        },
        {
            name: "Model",
            selector: (row) => row.model,
        },
        {
            name: "Year",
            selector: (row) => row.year,
        },
        {
            name: "Serial",
            selector: (row) => row.serial,
        },
        {
            name: "Listing",
            selector: (row) => row.listing?.title || "Not assigned",
        },
        {
            cell: (row) => (
                <>
                    <button
                        className="btn btn-sm btn-outline-primary"
                        onClick={() => editAsset(row.id)}
                    >
                        <i className="fa fa-pen"></i>
                    </button>
                    <button
                        className="btn btn-sm btn-outline-primary"
                        onClick={() => deleteAsset(row.id)}
                    >
                        <i className="fa fa-trash"></i>
                    </button>
                </>
            ),
            name: "Delete",
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];

    return (
        <DataTable
            title="Assets"
            columns={columns}
            data={data}
            // selectableRows
            // contextActions={contextActions}
            // onSelectedRowsChange={handleRowSelected}
            // clearSelectedRows={toggleCleared}
            pagination
            dense
            progressPending={loading}
        />
    )
}

export default AssetsList;