import { styled } from "styled-components";
import Input from "../../components/Input";
import Button from "../../components/Button";
import { Link } from 'react-router-dom';
import { PATHS } from "../../../../../utils";

const Fieldset = styled.fieldset`
    display: flex;
    flex-direction: column;
    gap: 50px;
`;

const ForgotPasswordLink = styled(Link)`
    align-self: center;
    color: white;
    font-size: 1.2rem;
`;

const SignUpLink = styled(Link)`
    align-self: center;
    padding: 15px;
    border-radius: 10px;
    background-color: #bbc900;
    color: white;
    text-align: center;
    font-size: 1.2rem;
`;

const Page = ({
    isDisabled,
    email,
    password,
    onSubmit,
}) =>
    <form onSubmit={onSubmit}>

      <Fieldset disabled={isDisabled}>

        <Input type="text" placeholder="Email" {...email} />
        <Input type="password" placeholder="Password" {...password} />
        <Button type="submit">Log In</Button>
        <ForgotPasswordLink to={PATHS.FORGOT_PASSWORD}>Forgot password?</ForgotPasswordLink>

        <hr />

        <SignUpLink to={PATHS.SIGNUP}>Create new account</SignUpLink>

      </Fieldset>

    </form>;

export default Page;
