/** @format */

import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { logoutAction } from "../../redux/actions/APIs";
import { Link } from "react-router-dom";
import * as image from "../../resources/images";

import { PATHS } from "../../utils";

const SecureTopbar = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  });

  /* Method that will fix header after a specific scrollable */
  const isSticky = (e) => {
    const header = document.querySelector(".inner-header");
    const scrollTop = window.scrollY;
    scrollTop >= 50
      ? header.classList.add("is-sticky")
      : header.classList.remove("is-sticky");
  };
  return (
    <header id="header" className="d-flex align-items-center inner-header">
      <div className="container-fluid d-flex align-items-center justify-content-between">
        <nav className="navbar navbar-expand-lg w-100">
          <div className="container-fluid">
            <div className="d-flex align-items-center justify-content-start">
              <Link to={PATHS.LANDINGPAGE} className="navbar-brand" href="#">
                UNITWATCH
              </Link>

            </div>
            <div
              className="collapse navbar-collapse justify-content-center"
              id="navbarSupportedContent"
            >
            </div>
            <div className="sign-links">
              <div className="profile-sec">
                <div className="dropdown">
                  <a
                    className="dropdown-toggle"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i className={"fa fa-gear"}></i>
                  </a>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li>
                      <Link to={PATHS.HOME} className="dropdown-item" href="#">
                        Dashboard
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={PATHS.BASICDETAILS}
                        className="dropdown-item"
                        href="#"
                      >
                        Profile
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={PATHS.CHANGEPASSWORD}
                        className="dropdown-item"
                        href="#"
                      >
                        Change Password
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={"#"}
                        onClick={() => dispatch(logoutAction())}
                        className="dropdown-item"
                        href="#"
                      >
                        Logout
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>

              <button
                className="navbar-toggler ms-3"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default SecureTopbar;
