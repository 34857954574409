import {Offcanvas} from "react-bootstrap";
import RepairLogForm from "./RepairLogForm";

const RepairLogDrawer = ({handleClose, show, props, repairLogId, fetchData}) => {
    return (
        <>
            <Offcanvas show={show} onHide={handleClose} {...props}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>{repairLogId ? 'Update repair log' : 'Create repair log'}</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <RepairLogForm fetchData={fetchData} handleClose={handleClose} repairLogId={repairLogId}/>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
};

export default RepairLogDrawer;