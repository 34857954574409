/** @format */

import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
export { PrivateRoute, PublicRoute };

export const API_BASE_PATH = process.env.REACT_APP_API_BASE_PATH;
export const HOST_APP_BASE_PATH = process.env.REACT_APP_HOST_BASE_PATH;
//export const API_BASE_PATH = "http://192.168.6.121:5000/api/v1";
//  export const API_BASE_PATH = "https://vickyknowsapi.com/api/v1";
export const PATHS = {
  LANDINGPAGE: "/",
  SIGNUP: "/signup",
  SIGNIN: "/signin",
  FORGOT_PASSWORD: "/forgot-password",
  HOME: "/home",
  PROPERTYLIST: "/property-listing",
  SEARCHPROPERTY: "/search-property-listing",

  OFFERLIST: "/property-offer/:id",
  OFFERLIST_STR: "/property-offer/",

  EDITPROPERTY: "/update-property/:slug",
  EDITPROPERTY_STR: "/update-property/",

  CONTACTUS: "/contact-us",

  UPLOAD_PHOTO: "/profile/avatar-photo/:id",
  UPLOAD_PHOTO_STR: "/profile/avatar-photo/",
  CHANGEPASSWORD: "/profile/change-password",

  BASICDETAILS: "/profile/basic-details/",

  RESETPASSWORD: "/reset-password/:token",
  RESETPASSWORD_STR: "/reset-password/",
  VERIFYPASSWORD: "/verify-account/:token",
  VERIFYPASSWORD_STR: "/verify-account/",

  ACCESS_DENIED: "/access-denied",

  CMSPAGE: "/page/:slug",
  CMSPAGE_STR: "/page/",

  PROPERTYDETAIL: "/property-details/:slug",
  PROPERTYDETAIL_STR: "/property-details/",

  NEWSDETAILS: "/news-details/:slug",
  NEWSDETAILS_STR: "/news-details/",
  NEWS: "/news-listing",

  ADDPROPERTY: "/add-property",
  NEWRESETPASSWORD: "/base/NewresetPassword",
  ASSUMTIONS: "/base/assumtions",

  ABOUTUS: "/profile/about-us/:id",
  ABOUTUS_STR: "/profile/about-us/",

  SUCCESS: "/base/success",
  TOKENERROR: "/base/tokenerror",
  CHATBOX: "/base/chatbox",
  PROVIDERFLOW: "/providerflow",
  SOCIALLESSION: "/base/sociallesson",
  SOCIALCOURSE: "/dimension/social-lessons/:id/:courseId/:skillId/:activityId",
  SOCIALCOURSE_STR: "/dimension/social-lessons/",
  PUBLIC_ADDCOURSE: "/add-content",
  SOCIALLESSONGRP: "/base/socialgrp",

  HOWVICKYCANHELPS: "/Vickyhelp",
  INTEL_DETAIL: "/intellectual-details/:id/:courseId",
  INTEL_DETAIL_STR: "/intellectual-details/",

  CHATBOXPATH: "/messaging/:id",
  CHATBOXPATH_STR: "/messaging/",
  CHECKOUT: "/base/checkout",
  Preview: "/base/preview",
  LANDING: "/base/landing",

  REPAIR_LOGS: "/repair-logs",
  ASSETS: "/assets",
};

export const API_PATHS = {
  FREE_SIGNUP_API: `${API_BASE_PATH}/register`,
  FREE_SIGNIN_API: `${API_BASE_PATH}/login`,
  USERVERIFY_API: `${API_BASE_PATH}/confirm-account`,
  CHANGE_PASSWORD_API: `${API_BASE_PATH}/change-password`,
  FORGOT_API: `${API_BASE_PATH}/forgetpassword`,
  RESET_API: `${API_BASE_PATH}/user/resetPassword`,
  RESET_PASSWORD_API: `${API_BASE_PATH}/resetPassword`,

  LANDINGPAGE_API: `${API_BASE_PATH}/get-homepage`,
  CMS_API: `${API_BASE_PATH}/get-cms-page`,
  GET_PROPERTY_API: `${API_BASE_PATH}/get-property-list`,
  GET_PROP_DETAIL_API: `${API_BASE_PATH}/get-property`,
  PROPERTY_OFFER: `${API_BASE_PATH}/get-property-info`,
  CHANGE_STATUS: `${API_BASE_PATH}/change-property-status`,
  SEND_ENQUIRY: `${API_BASE_PATH}/store-enquiries`,
  SUBMIT_OFR_API: `${API_BASE_PATH}/submit-property-offer`,

  USER_DETAILBY_ID_API: `${API_BASE_PATH}/get-profile`,
  UPDATE_BASIC_DETAIL_API: `${API_BASE_PATH}/update-profile`,

  UPDATE_PROFILE_PICTURE_API: `${API_BASE_PATH}/users/upload`,

  GETNEWS_DETAIL_STR: `${API_BASE_PATH}/get-single-news`,
  GET_NEWS_API: `${API_BASE_PATH}/news`,
  ADDPROPERTY_API: `${API_BASE_PATH}/add-property`,
  UPDATE_PROPERTY: `${API_BASE_PATH}/edit-property`,
  DELETE_PROPERTY: `${API_BASE_PATH}/delete-property`,

  SEARCH_PROPERTY_API: `${API_BASE_PATH}/search-result`,
  REMOVE_AVATAR_API: `${API_BASE_PATH}/users/delete/`,
  GET_EDUCATION_API: `${API_BASE_PATH}/education/`,
  UPDATE_EDUCATION_API: `${API_BASE_PATH}/education/`,
  GET_COURSE_API: `${API_BASE_PATH}/education/courses`,
  // UPDATE_EXPERIENCE_API: `${API_BASE_PATH}/experience/`,

  UPLOAD_INTEREST_PICTURE_API: `${API_BASE_PATH}/interests/upload/`,
  GET_DIMENSIONS_API: `${API_BASE_PATH}/dimensions`,
  UPDATE_SCHOOL_API: `${API_BASE_PATH}/education/schools/`,

  GET_REPAIR_LOGS_API: `${API_BASE_PATH}/repair-logs`,
  GET_REPAIR_LOG_API: `${API_BASE_PATH}/repair-log`,
  CREATE_REPAIR_LOG_API: `${API_BASE_PATH}/create-repair-log`,
  DELETE_REPAIR_LOG_API: `${API_BASE_PATH}/delete-repair-log`,
  UPDATE_REPAIR_LOG_API: `${API_BASE_PATH}/update-repair-log`,
  REMOVE_REPAIR_LOG_IMAGE_API: `${API_BASE_PATH}/remove-repair-log-image`,

  GET_ASSETS_API: `${API_BASE_PATH}/assets`,
  GET_ASSET_API: `${API_BASE_PATH}/asset`,
  CREATE_ASSET_API: `${API_BASE_PATH}/create-asset`,
  DELETE_ASSET_API: `${API_BASE_PATH}/delete-asset`,
  UPDATE_ASSET_API: `${API_BASE_PATH}/update-asset`,
  REMOVE_ASSET_IMAGE_API: `${API_BASE_PATH}/remove-asset-image`,
};
