import { styled } from "styled-components";

const StyledBanner = styled.img`
  grid-area: banner;
  margin: 0 auto;
  //height: 100%;
  max-width: 100%;
  max-height: 100vh;
  width: auto;

  @media (max-width: 768px) {
    display: none;
  }
`;

const Banner = () =>
    <StyledBanner src="/public-layout-banner.png" alt="Banner Image"  />;

export default Banner;
