import { Link } from 'react-router-dom';
import { styled } from "styled-components";
import { PATHS } from '../../../../utils';

const StyledNav = styled.nav`
    font-size: 20px;

    &, & > a {
        color: white;
    }
`;

const Nav = () =>
    <StyledNav>
        <Link to={PATHS.SIGNUP}>Sign Up</Link>
        {" | "}
        <Link to={PATHS.SIGNIN}>Log In</Link>
    </StyledNav>;

export default Nav;
